import { ProductMnemoCodes } from '@libs/components/misc/ProductCard/types';
import type { TariffFilter as TariffFilterReq } from '@libs/types';

export const productBtnName: { [key: string]: string } = {
    [ProductMnemoCodes.TPC_ALGO_PACK]: 'Открыть ALGOPACK',
    [ProductMnemoCodes.MOEX_DATA]: 'Открыть',
    [ProductMnemoCodes.INVESTOR_PRO]: 'Открыть Investor Pro',
    [ProductMnemoCodes.MARKET_DIVE]: 'Открыть',
    [ProductMnemoCodes.COMPLIENCE_TOOL]: 'Открыть',
    [ProductMnemoCodes.TPC_CENOVOI_CENTR]: 'Открыть Ценовой Центр',
    [ProductMnemoCodes.TPC_PRO_PACK]: 'Открыть',
    [ProductMnemoCodes.CKI]: 'Открыть',
    [ProductMnemoCodes.TRADE_RADAR]: '',
};

export const tariffBodyReq: TariffFilterReq = {
    pageable: {
        pageSize: 10,
        pageNumber: 0,
        sortField: 'id',
        sortOrder: 'asc',
    },
};
