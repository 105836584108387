import { useState } from 'react';
import { tabs } from '@modules/Algopack/AlgopackPage/components/Opportunities/data';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useResize } from '@libs/utils';

import styles from './styles.module.scss';

export const Opportunities = () => {
    const [activeTab, setActiveTab] = useState(tabs[0]);
    const { isScreenXl } = useResize();

    return (
        <section className={classNames(styles.opportunitiesWrapper, 'flex')}>
            <div className={classNames('flex', 'flex-column')}>
                <h1>{activeTab.title}</h1>
                <div className={classNames(styles.navigationBlockWrapper)}>
                    <div className={classNames('flex', 'flex-nowrap')}>
                        {tabs.map((item) => (
                            <span
                                key={item.title}
                                onClick={() => setActiveTab(item)}
                                className={classNames(activeTab.code === item.code && styles.selected)}
                            >
                                {item.code}
                            </span>
                        ))}
                    </div>
                </div>
                {!isScreenXl && <img src={activeTab.image} alt={activeTab.code} />}
                <div className={classNames(styles.contentWrapper)}>
                    <p>{activeTab.description}</p>
                    <Link to={`${activeTab.link}`} reloadDocument>
                        <span>Смотреть</span>
                    </Link>
                </div>
            </div>
            {isScreenXl && <img src={activeTab.image} alt={activeTab.code} />}
        </section>
    );
};
