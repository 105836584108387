import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollTop = (delay?: number, state?: any) => {
    const location = useLocation();
    const { pathname } = location;

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!location.state?.goToProducts && !location.hash.includes('tariffs')) {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }
        }, delay);

        return () => clearTimeout(timer);
    }, [pathname, delay]);
};

export default useScrollTop;
