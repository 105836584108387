import { Feedback, Footer, Navigation, TariffBlock } from '@libs/components';
import type { RefObjectType } from '@libs/types';
import { type AppDispatch, type RootState } from '@store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';
import { checkSubscriptionThunk } from '@store/store/thunk/algopack/subscribe.thunk';
import { addMetaTag, removeMetaTag, scrollElementByRef } from '@libs/utils';
import { ProductMnemoCodes } from '@libs/components/misc/ProductCard/types';
import { useLocation } from 'react-router-dom';

import styles from './styles.module.scss';
import { MainLogo } from './components/MainLogo';
import { PosAlgopack } from './components/PosAlgopack';
import { ApiData } from './components/ApiData';
import { Hackathon } from './components/Hackathon';
import { Examples } from './components/Examples';
import { Links } from './components/Links';
import { DataConstitution } from './components/DataConstitution';
import { PageDisclaimer } from './components/PageDisclaimer';
import { OpenFutures } from './components/OpenFutures';
import { Opportunities } from './components/Opportunities';

export const AlgopackPage = () => {
    const dispatch = useDispatch<AppDispatch>();
    const refs: RefObjectType<HTMLDivElement> = {};
    const location = useLocation();

    const container = useRef<HTMLDivElement>(null);

    const activeTariff = useSelector((state: RootState) => state.algopack.activeTariff);
    const productGuids = useSelector((state: RootState) => state.product.productGuids);
    const isProductLoading = useSelector((state: RootState) => state.product.isLoading);

    const setChildRefs = (childRefs: RefObjectType<HTMLDivElement>) => {
        Object.assign(refs, childRefs);
    };

    useEffect(() => {
        if (location.hash.includes('tariffs')) {
            setTimeout(() => {
                const ref = refs['tariffsRef'];

                if (ref && ref.current) {
                    scrollElementByRef(ref);
                }
            }, 500);
        }
    }, [refs]);

    useEffect(() => {
        if (productGuids[ProductMnemoCodes.TPC_ALGO_PACK]) {
            dispatch(checkSubscriptionThunk(productGuids[ProductMnemoCodes.TPC_ALGO_PACK]));
        }
    }, [productGuids]);

    useEffect(() => {
        document.title = 'ALGOPACK';
        addMetaTag('title', 'AGLOPACK: торговля через API | Алгоритмическая торговля с помощью Datashop');
        addMetaTag(
            'description',
            'AgloPack - ваш ключ к алгоритмической торговле через API. Сервис предоставляет все необходимые инструменты для онлайн трейдинга, включая доступ к данным и аналитическим сигналам с Московской биржи. Получите преимущество с нашими алгоритмами, библиотеками и примерами кода для создания и тестирования торговых стратегий.',
        );

        const el = container.current;
        const timer = setTimeout(() => {
            if (el) el.style.opacity = '1';
        }, 250);

        return () => {
            removeMetaTag('title');
            removeMetaTag('description');
            clearTimeout(timer);
        };
    }, []);

    return (
        <>
            <div className="main-wrapper">
                <div ref={container} style={{ opacity: 0 }}>
                    <Navigation />
                    <MainLogo refs={refs} />
                    <Opportunities />
                    <PosAlgopack />
                    <DataConstitution />
                    <ApiData />
                    {/*<HowPlug />*/}
                    <Links />
                    <OpenFutures />
                    <Hackathon />
                    <Examples />
                    {/* <ExampleGraph /> */}
                    {!isProductLoading && (
                        <TariffBlock
                            setRefs={setChildRefs}
                            productGuid={productGuids[ProductMnemoCodes.TPC_ALGO_PACK]}
                            activeTariff={activeTariff}
                            className={styles.tariffBg}
                        />
                    )}
                    <PageDisclaimer />
                    <Feedback isAbsolute />
                </div>
            </div>
            <Footer />
        </>
    );
};
